/* eslint-disable @typescript-eslint/indent */
/* eslint-disable operator-linebreak */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { MRTScheduleInputButton } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/MRTScheduleInputButton';
import { MRTBirthDayInputMobile } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/MyRealTripBirthDayInput.mobile';
import { MRTCitySearchInput } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/SearchInput/MRTCitySearchInput';
import { SPACING, COLORS2 } from '^/constants/commonStyles';

export const MRTCarListingSearchBoxMobileRoot: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
}> = observer(function MRTCarListingSearchBoxMobileRoot({ viewmodel }) {
  const hasDifferentPickupReturnLocations = viewmodel.i.hasDifferentPickupReturnLocations.value;

  return (
    <>
      <div>
        {/* 대여/반납 장소가 다릅니다. */}
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            if (isOntra(viewmodel.i.pickupCityCode.value)) {
              viewmodel.showDifferentLocationAlert.set(true);
              return;
            }
            viewmodel.i.hasDifferentPickupReturnLocations.set(!hasDifferentPickupReturnLocations);
            viewmodel.i.returnCityCode.set(null);
            viewmodel.i.returnLocationId.set(null);
            viewmodel.i.returnCitySearch.searchInput.set('');
            viewmodel.i.scheduleTimeViewModel.reset();
            viewmodel.i.calendarStateViewModel.reset();
          }}
          className={cx(
            css`
              display: flex;
              gap: ${SPACING.spacing8};
              align-items: center;

              margin-top: ${SPACING.spacing8};
              width: fit-content;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #434343;
              background-color: #eef6fe;
              width: 100%;
              padding: ${SPACING.spacing8} 20px;
              border-radius: 8px;
            `,
          )}
        >
          <img
            alt="rental-location-different"
            src={
              hasDifferentPickupReturnLocations
                ? 'ic_checkbox/checkedbox.svg'
                : 'ic_checkbox/uncheckedbox.svg'
            }
          />
          다른 곳에서 반납하고 싶어요.
        </div>
      </div>
      <div
        className={css`
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #434343;
          margin-top: 16px;
        `}
      >
        대여/반납 장소
      </div>
      <div
        role="textbox"
        tabIndex={0}
        onClick={() => {
          viewmodel.stepManager.pushStep({ type: '1_rental_city' });
        }}
        className={cx(
          css`
            display: flex;
            align-items: center;
            padding: 12px 16px;
            height: 44px;
            margin-top: ${SPACING.spacing8};
            border-radius: 8px;
            border: 1px solid #cecece;
            color: #9f9f9f;
          `,
        )}
      >
        <MRTCitySearchInput
          placeholder={hasDifferentPickupReturnLocations ? '대여 장소' : '어디로 떠나시나요?'}
          styleOverride={{
            root: cx(
              css`
                display: flex;
                width: 100%;
              `,
            ),
            textInput: cx(
              css`
                line-height: 20px;
                font-size: 14px;
                font-weight: 500;
              `,
            ),
          }}
          placeholderIcon="marker-pin_20.svg"
          viewmodel={viewmodel.i.pickupCitySearch}
          onSelectCity={() => {}}
          office={viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description}
          readOnly
        />
      </div>
      {hasDifferentPickupReturnLocations && (
        <div
          role="textbox"
          tabIndex={0}
          onClick={() => {
            viewmodel.stepManager.pushStep({ type: '5_return_city' });
          }}
          className={css`
            display: flex;
            align-items: center;
            padding: 20px 16px;
            height: 44px;
            margin-top: ${SPACING.spacing6};
            border-radius: 8px;
            border: 1px solid #cecece;
            background-color: ${!viewmodel.i.pickupCitySearch.searchInput.value ||
            !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
              ? '#F4F4F4'
              : '#FFF'};
          `}
        >
          <MRTCitySearchInput
            placeholder="반납 장소"
            styleOverride={{
              root: cx(
                css`
                  display: flex;
                  width: 100%;
                `,
              ),
              textInput: cx(
                css`
                  line-height: 20px;
                  font-size: 14px;
                  font-weight: 500;
                `,
              ),
            }}
            placeholderIcon="marker-pin_20.svg"
            viewmodel={viewmodel.i.returnCitySearch}
            onSelectCity={() => {}}
            office={viewmodel.returnLocation?.locationDescriptions.get('ko')?.description}
            disabled={
              !viewmodel.i.pickupCitySearch.searchInput.value ||
              !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
            }
          />
        </div>
      )}
      <div
        className={cx(
          css`
            margin-top: 16px;
          `,
        )}
      >
        <MRTScheduleInputButton
          viewmodel={viewmodel}
          onClick={() => viewmodel.stepManager.pushStep({ type: '3_schedule' })}
          isSelected={viewmodel.stepManager.currentStep?.type === '3_schedule'}
          disabled={
            hasDifferentPickupReturnLocations
              ? !viewmodel.i.pickupCitySearch.searchInput.value ||
                !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description ||
                !viewmodel.i.returnCitySearch.searchInput.value ||
                !viewmodel.returnLocation?.locationDescriptions.get('ko')?.description
              : !viewmodel.i.pickupCitySearch.searchInput.value ||
                !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
          }
          placeholderIcon="calendar.svg"
          inputStyle={cx(
            css`
              margin-top: 8px;
            `,
          )}
          title="대여/반납 일정"
          placeholder="대여 일시"
          value={viewmodel.i.calendarStateViewModel.selection.start}
          type="pickup"
        />
        <MRTScheduleInputButton
          viewmodel={viewmodel}
          onClick={() => viewmodel.stepManager.pushStep({ type: '3_schedule' })}
          isSelected={viewmodel.stepManager.currentStep?.type === '3_schedule'}
          disabled={
            hasDifferentPickupReturnLocations
              ? !viewmodel.i.pickupCitySearch.searchInput.value ||
                !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description ||
                !viewmodel.i.returnCitySearch.searchInput.value ||
                !viewmodel.returnLocation?.locationDescriptions.get('ko')?.description
              : !viewmodel.i.pickupCitySearch.searchInput.value ||
                !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
          }
          placeholderIcon="calendar.svg"
          placeholder="반납 일시"
          inputStyle={cx(
            css`
              margin-top: 4px;
            `,
          )}
          value={viewmodel.i.calendarStateViewModel.selection.end}
          type="return"
        />
      </div>
      <div
        className={css`
          margin-top: 16px;
        `}
      >
        <MRTBirthDayInputMobile viewmodel={viewmodel} />
      </div>
      <button
        type="submit"
        disabled={viewmodel.submitActionVM.status !== 'success'}
        className={cx(
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${COLORS2.main.MrtBlue1} !important;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            color: ${COLORS2.main.MrtWhite};
            margin-top: 24px;
            font-size: 16px;
            font-weight: 600;

            &:disabled {
              background-color: ${COLORS2.main.MrtBlue2} !important;
            }
          `,
        )}
        onClick={async () => {
          if (viewmodel.submitActionVM.status === 'success') {
            viewmodel.stepManager.clearStep();
            // sendTrackingLog({
            //   action: actions['/'].CLICK_SEARCH_CARS,
            //   country: sessionStorage.getItem('selectedCountry') || '',
            // });
            const submitFn = viewmodel.submitActionVM.value;
            setTimeout(() => {
              submitFn.submit();
            }, 50);
          }
        }}
      >
        검색
      </button>
    </>
  );
});

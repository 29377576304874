import { z } from 'zod';

import { DayType } from '^/components/Calendar/BasicCalendar/components/Day/DayType';
import {
  TimeSelectHour,
  TimeSelectMinute,
} from '^/components/Calendar/BasicCalendar/components/TimeSelect/TimeSelect.constants';

export const DatetimeWithoutTimezone = z.string().brand<'DatetimeWithoutTimezone'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DatetimeWithoutTimezone = z.infer<typeof DatetimeWithoutTimezone>;

export function encodeDatetimeWithoutTimezone(
  day: DayType,
  hour: TimeSelectHour,
  min: TimeSelectMinute,
) {
  return `${day.str}T${hour}:${min}:00` as DatetimeWithoutTimezone;
}

export function decodeDatetimeWithoutTimezone(d: DatetimeWithoutTimezone) {
  const splitByT = d.split('T');
  const splitYMD = splitByT[0].split('-');
  const dayStr = splitByT[0];
  const timeStr = splitByT[1];
  const timeStrSplit = timeStr.split(':');
  return {
    day: splitYMD[2],
    dayStr,
    hour: timeStrSplit[0] as TimeSelectHour,
    minute: timeStrSplit[1] as TimeSelectMinute,
  };
}
export const BirthdayType = z.string().brand<'BirthdayType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BirthdayType = z.infer<typeof BirthdayType>;

export const ShortBirthdayType = z.string().brand<'ShortBirthday'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ShortBirthdayType = z.infer<typeof ShortBirthdayType>;

export function fromShortBirthday(s: ShortBirthdayType, now = new Date()): BirthdayType {
  const nowFullYr = String(now.getFullYear());
  const nowCentury = Number(nowFullYr.slice(0, -2));
  const nowPartialYear = nowFullYr.slice(2, 4);
  const partialYear = s.slice(0, 2);
  const century = partialYear < nowPartialYear ? nowCentury : nowCentury - 1;
  const birthYear = `${century}${s.substring(0, 2)}`;
  const birthMonth = s.substring(2, 4);
  const birthDay = s.substring(4, 6);
  return `${birthYear}-${birthMonth}-${birthDay}` as BirthdayType;
}

export function toShortBirthday(b: BirthdayType) {
  const split = b.split('-');
  return `${split[0].slice(-2)}${split[1]}${split[2]}` as ShortBirthdayType;
}

export const NthDayOfWeek = z.number().brand<'NthDayOfWeek'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NthDayOfWeek = z.infer<typeof NthDayOfWeek>;

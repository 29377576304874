/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { ArrowDwon } from '^/commonComponents/ui/ArrowDown';
import { SPACING, COLORS2, font, INPUT } from '^/constants/commonStyles';

import { classNames } from '../../contentsArea/CarSearchBox.contentsArea.style';
import { CarListingSearchBoxViewModel } from '../../viewmodel/CarListingSearchBox.viewmodel';

export const ScheduleInputButton: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  title?: React.ReactNode;
  smaller?: boolean;
  onClick?: () => void;
  isSelected: boolean;
}> = observer(function ScheduleInputButton({
  viewmodel,
  title = '대여 / 반납 일정',
  smaller,
  onClick,
  isSelected,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const selectedDate = viewmodel.i.calendarStateViewModel.selection;
  const selectedStartDayKo = selectedDate.start?.dayKoFromStr;
  const selectedEndDayKo = selectedDate.end?.dayKoFromStr;

  const selectedStartMonth = selectedDate?.start?.monthFrom0;
  const selectedStartDay = selectedDate.start?.day;
  const selectedEndMonth = selectedDate?.end?.monthFrom0;
  const selectedEndDay = selectedDate.end?.day;
  const { scheduleTimeViewModel } = viewmodel.i;
  const pickupHour = scheduleTimeViewModel.pickupHour.value;
  const pickupMinute = scheduleTimeViewModel.pickupMinute.value;

  const returnHour = scheduleTimeViewModel.returnHour.value;
  const returnMinute = scheduleTimeViewModel.returnMinute.value;

  const formatedDateAndTimeStr =
    (selectedStartMonth !== undefined &&
      selectedStartDay &&
      selectedEndMonth !== undefined &&
      selectedEndDay &&
      selectedStartDayKo &&
      selectedEndDayKo &&
      pickupHour &&
      pickupMinute &&
      `${
        selectedStartMonth + 1
      }.${selectedStartDay}(${selectedStartDayKo}) ${pickupHour}:${pickupMinute} ~ ${
        selectedEndMonth + 1
      }.${selectedEndDay}(${selectedEndDayKo}) ${returnHour}:${returnMinute}`) ||
    null;

  return (
    <div className={cx(classNames.common.searchBoxInputWrapper, font.Body1_Bold)}>
      <div className={cx(css``, font.Caption1_Light)}>{title}</div>

      <div
        onClick={onClick}
        className={cx(
          classNames.common.searchBoxInputs,
          classNames.common.searchBoxInputWrapper,
          css`
            flex-direction: row;
            justify-content: space-between;

            background-color: ${COLORS2.sub.MrtGray3};
            /* background-color: orange; */

            padding: ${SPACING.spacing16};
            margin-top: 12px;
            border-radius: 5px;
          `,
          smaller &&
            css`
              margin-top: 0;
            `,
          isSelected &&
            css`
              border: 1px solid ${COLORS2.main.MrtBlue1};
              color: ${COLORS2.main.MrtBlack};
              background-color: ${COLORS2.main.MrtWhite};
            `,
          isMobile &&
            css`
              margin-top: ${SPACING.spacing16};
              padding-left: 12px;
            `,
        )}
      >
        <div
          className={cx(
            selectedDate.start &&
              selectedDate.end &&
              css`
                color: ${COLORS2.main.MrtBlack};
              `,
            INPUT.overflowEllipsis,
          )}
        >
          {formatedDateAndTimeStr || '일정 선택'}
        </div>

        <ArrowDwon color={isSelected ? 'black' : 'gray'} />
      </div>
    </div>
  );
});

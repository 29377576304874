/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { DayType } from '^/components/Calendar/BasicCalendar/components/Day/DayType';
import { SPACING, COLORS2, font, INPUT } from '^/constants/commonStyles';

import { classNames } from '../../../base/desktop/contentsArea/CarSearchBox.contentsArea.style';
import { CarListingSearchBoxViewModel } from '../../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';

export const MRTScheduleInputButton: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  title?: React.ReactNode;
  smaller?: boolean;
  onClick?: () => void;
  isSelected: boolean;
  placeholder?: string;
  placeholderIcon?: string;
  inputStyle?: string;
  value: DayType | null;
  valueEnd?: DayType | null;
  disabled?: boolean;
  type: 'pickup' | 'return' | 'pickupReturn';
}> = observer(function MRTScheduleInputButton({
  viewmodel,
  title,
  smaller,
  onClick,
  isSelected,
  placeholder,
  placeholderIcon,
  inputStyle,
  value,
  valueEnd,
  disabled,
  type,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { scheduleTimeViewModel } = viewmodel.i;
  const selectedStartDayKo = value?.dayKoFromStr;
  const selectedStartMonth = value?.monthFrom0;
  const selectedStartDay = value?.day;

  const selectedEndDayKo = valueEnd?.dayKoFromStr;
  const selectedEndMonth = valueEnd?.monthFrom0;
  const selectedEndDay = valueEnd?.day;

  const pickupHourNum = Number(scheduleTimeViewModel.pickupHour.value);
  const isPickupPM = scheduleTimeViewModel.pickupHour.value && pickupHourNum > 11;
  const pickupHour = pickupHourNum > 12 ? pickupHourNum - 12 : pickupHourNum;
  const pickupMinute = scheduleTimeViewModel.pickupMinute.value;

  const returnHourNum = Number(scheduleTimeViewModel.returnHour.value);
  const isReturnPM = scheduleTimeViewModel.returnHour.value && returnHourNum > 11;
  const returnHour = returnHourNum > 12 ? returnHourNum - 12 : returnHourNum;
  const returnMinute = scheduleTimeViewModel.returnMinute.value;

  const formatedDateAndTimeStr = () => {
    // pickupReturn (기간 표시)
    if (value && valueEnd && type === 'pickupReturn') {
      const startDateStr = `${
        Number(selectedStartMonth) + 1
      }월 ${selectedStartDay}일 (${selectedStartDayKo}) ${
        isPickupPM ? '오후' : '오전'
      } ${pickupHour}:${pickupMinute}`;

      const endDateStr = `${
        Number(selectedEndMonth) + 1
      }월 ${selectedEndDay}일 (${selectedEndDayKo}) ${
        isReturnPM ? '오후' : '오전'
      } ${returnHour}:${returnMinute}`;

      return startDateStr + ' - ' + endDateStr;
    }

    // pickup
    if (type === 'pickup' && pickupHourNum && pickupMinute) {
      return `${Number(selectedStartMonth) + 1}월 ${selectedStartDay}일 (${selectedStartDayKo}) ${
        isPickupPM ? '오후' : '오전'
      } ${pickupHour}:${pickupMinute}`;
    }

    // return
    if (type === 'return' && returnHourNum && returnMinute) {
      return `${Number(selectedStartMonth) + 1}월 ${selectedStartDay}일 (${selectedStartDayKo}) ${
        isReturnPM ? '오후' : '오전'
      } ${returnHour}:${returnMinute}`;
    }

    return null;
  };

  return (
    <div
      className={cx(
        classNames.common.searchBoxInputWrapper,
        font.Body1_Bold,
        // borders.DEBUG_greenBorderDashed,
      )}
    >
      {title && (
        <div
          className={cx(
            font.Caption1_Light,
            css`
              color: #434343;
            `,
          )}
        >
          {title}
        </div>
      )}

      <div
        onClick={disabled ? undefined : onClick}
        data-step-id="3_schedule"
        className={cx(
          classNames.common.searchBoxInputsMRT,
          classNames.common.searchBoxInputWrapper,
          css`
            flex-direction: row;

            padding: ${SPACING.spacing16};
            margin-top: 8px;
            border: 1px solid #cecece;
            background-color: ${disabled ? '#F4F4F4' : '#fff'};
          `,
          smaller &&
            css`
              margin-top: 0;
            `,
          isSelected &&
            css`
              border: 1px solid #cecece;
              color: ${COLORS2.main.MrtBlack};
            `,
          isMobile &&
            css`
              margin-top: ${SPACING.spacing4};
              padding-left: 16px;
            `,
          !isMobile &&
            css`
              padding: 14px 16px;
              height: 48px;
            `,
          inputStyle && inputStyle,
        )}
      >
        {placeholderIcon && (
          <img
            alt="myrealtrip_city_input_icon"
            src={placeholderIcon}
            className={css`
              margin-right: 6px;
            `}
          />
        )}
        <div
          className={cx(
            css`
              color: ${disabled && !value
                ? '#BBBBBB'
                : !disabled && !value
                ? '#9F9F9F'
                : '#1E1E1E'};
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            `,
            !isMobile &&
              css`
                font-size: 16px;
              `,
            INPUT.overflowEllipsis,
          )}
        >
          {formatedDateAndTimeStr() || placeholder}
        </div>
      </div>
    </div>
  );
});

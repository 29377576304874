export type YearMonth = {
  year: number;
  monthFrom0: number;
};

export function yearMonthFromDate(d: Date): YearMonth {
  return {
    year: d.getFullYear(),
    monthFrom0: d.getMonth(),
  };
}

export function addToYearMonth({ year, monthFrom0: month }: YearMonth, months: number): YearMonth {
  const addedMonth = month + months;
  const newMonth = addedMonth % 12;

  return {
    year: Math.floor(year + addedMonth / 12),
    monthFrom0: newMonth < 0 ? newMonth + 12 : newMonth,
  };
}

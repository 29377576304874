/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { HoverToReveal } from '^/commonComponents/ui/HoverToReveal';
import { InsuranceInfoBox } from '^/components/InsuranceInfo/insuranceInfo';
import { GroupCar, NameCar } from '^/components/ModelCar';
import { borders, SPACING, MARGINS, COLORS2_AS_FOREGROUND, font2 } from '^/constants/commonStyles';
import { VENDOR_LOGOS } from '^/constants/LogoImages.constants';
import { translateSimplifiedServices } from '^/pages/Listing/common/translateServices';
import {
  isValidExtraEquipments,
  ReservationDetailed,
} from '^/types/ReservationDetailed/RawReservationDetailed';
import { capitalizeFirstLettersInWords } from '^/util/capitalizeFirstLetterInWords';

import { flexSplitHorizontalInHalf } from './ReservationDetailDesktop.commonStuff';
import { parseRentDateTime } from '^/util/rentDateTime';

export const ReservationDetailsVehicleScheduleInfo: React.FC<{
  data: ReservationDetailed;
}> = observer(function ReservationDetailsVehicleScheduleInfo({ data }) {
  const { t } = useTranslation();
  const { parsedPickupTime, parsedReturnTime, timeDiff } = parseRentDateTime(
    data?.pickup_datetime,
    data?.return_datetime,
    'YYYY.MM.DD (ddd) HH:mm',
  );

  const insurance = data.insurance_info_json;
  const services = data.services_json;
  const validEquips = isValidExtraEquipments(data.extra_equipments_json)
    ? data.extra_equipments_json
    : null;

  return (
    <div
      className={[
        'flex flex-col gap-[30px]',
        // borders.DEBUG_purpleBorderDashed,
      ].join(' ')}
    >
      <div>
        <div className="flex items-start justify-between">
          <div>
            <GroupCar
              modelName={data?.car_model}
              className={[
                font2.Headline1_Bold,
                MARGINS.TOP.spacing32,
                'first-letter:capitalize',
                // borders.DEBUG_blueBorderDashed,
              ].join(' ')}
              size={data?.size_car}
              type={data?.type_car}
            />
            <NameCar
              modelName={data?.car_model}
              className={[
                MARGINS.TOP.spacing16,
                COLORS2_AS_FOREGROUND.sub.MrtGray1,
                font2.Body1_Regular,
                // borders.DEBUG_blueBorderDashed,
              ].join(' ')}
            />
            <p className={[COLORS2_AS_FOREGROUND.sub.MrtGray1, font2.Body1_Regular].join(' ')}>
              {translateSimplifiedServices(services, t)}
            </p>
          </div>
          <div
            className={css`
              position: relative;
            `}
          >
            <img
              alt={data.car_model}
              src={data.image}
              onError={(e) => {
                e.currentTarget.src = '/NOT_FOUND_CAR.png';
              }}
              className={css`
                width: 180px;
                height: 102px;
                margin-right: 55px;
                margin-top: 10px;
              `}
            />
            <img
              alt="company"
              src={VENDOR_LOGOS[data.supplier]}
              className={[
                'h-5 w-11 object-scale-down',
                css`
                  position: absolute;
                  right: 0;
                  top: 0;
                `,
              ].join(' ')}
            />
          </div>
        </div>
      </div>

      <div
        className={[
          flexSplitHorizontalInHalf,
          // borders.DEBUG_orangeBorderDashed,
        ].join(' ')}
      >
        <div className={['flex flex-col gap-3'].join(' ')}>
          <p className={font2.Headline1_Bold}>예약 번호</p>
          <p className={font2.Body1_Regular}>{data.displayReservationId}</p>
        </div>
        <div className={['flex flex-col gap-3'].join(' ')}>
          <p className={font2.Headline1_Bold}>
            {t('rentaled')} / {t('return')} {t('schedule')}
          </p>
          <p className={font2.Body1_Regular}>
            {`${parsedPickupTime} ~ ${parsedReturnTime} (${timeDiff})`}
          </p>
        </div>
      </div>
      <div
        className={[
          flexSplitHorizontalInHalf,
          // borders.DEBUG_orangeBorderDashed,
          css`
            position: relative;
          `,
        ].join(' ')}
      >
        <div
          className={[
            'flex flex-col gap-3',
            // borders.DEBUG_goldDashed,
          ].join(' ')}
        >
          <p className={[font2.Headline1_Bold].join(' ')}>{t('insuranceDetails')}</p>
          <div
            className={[
              font2.Body1_Regular,
              // borders.DEBUG_greenBorderDashed,
              css`
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
              `,
            ].join(' ')}
          >
            <span>{insurance.code ? t(`insurance_name.${insurance.code}`) : '기본 할인요금'}</span>
            <HoverToReveal
              toReveal={
                <InsuranceInfoBox
                  insuranceName={
                    insurance.code ? t(`insurance_name.${insurance.code}`) : '기본 할인요금'
                  }
                  insuranceServices={data.insuranceServices as any}
                />
              }
            />
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <p className={[font2.Headline1_Bold].join(' ')}>{t('moreOptions')}</p>
          <p className={font2.Body1_Regular}>
            {(validEquips?.info?.length || 0) > 0 ? (
              <span>
                {validEquips?.info
                  ?.map((item) => `${t(`equipment_${item.code}`)} ${item.quantity}`)
                  .join(' / ')}
              </span>
            ) : (
              <p>{t('none')}</p>
            )}
          </p>
        </div>
      </div>

      <div
        className={[
          flexSplitHorizontalInHalf,
          // borders.DEBUG_orangeBorderDashed,
        ].join(' ')}
      >
        <div className={[].join(' ')}>
          <p className={[font2.Headline1_Bold].join(' ')}>
            {t('rentaled')} {t('location')}
          </p>
          <div
            className={[
              'flex flex-col',
              css`
                margin-top: 15px;
              `,
              // borders.DEBUG_greenBorderDashed,
            ].join(' ')}
          >
            <div className="flex items-center gap-2">
              <p className={font2.Body1_Regular}> {t(`location_name.${data.office_name}`)}</p>
              <div className="flex items-center gap-3">
                <img src="./ic-timer-24-px.png" className="h-5 w-5" />
                <span className={font2.Body1_Regular}>
                  {data.office_time.replace('null', '__')}
                </span>
              </div>
            </div>
            <pre
              className={[
                font2.Body1_Regular,
                COLORS2_AS_FOREGROUND.sub.MrtGray1,
                css`
                  line-height: 28px;
                  letter-spacing: -0.02px;
                `,
                // borders.DEBUG_blueBorderDashed,
              ].join(' ')}
            >
              {capitalizeFirstLettersInWords(data.office_address)}
              {data.office_phone && (
                <>
                  <br />
                  전화 번호: {data.office_phone}
                </>
              )}
            </pre>
          </div>
        </div>

        <div
          className={[
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <p className={[font2.Headline1_Bold].join(' ')}>
            {t('return')} {t('location')}
          </p>
          <div
            className={[
              'flex flex-col',
              css`
                margin-top: 15px;
              `,
              // borders.DEBUG_greenBorderDashed,
            ].join(' ')}
          >
            <div className="flex items-center gap-3">
              <p className={font2.Body1_Regular}>{t(`location_name.${data.office_second_name}`)}</p>
              <div className="flex items-center gap-3">
                <img src="./ic-timer-24-px.png" className="h-5 w-5" />
                <span className={font2.Body1_Regular}>
                  {data.office_second_time.replace('null', '__')}
                </span>
              </div>
            </div>
            <pre
              className={[
                font2.Body1_Regular,
                COLORS2_AS_FOREGROUND.sub.MrtGray1,
                css`
                  line-height: 28px;
                  letter-spacing: -0.02px;
                `,
                // borders.DEBUG_blueBorderDashed,
              ].join(' ')}
            >
              {capitalizeFirstLettersInWords(data.office_second_address)}
              {data.office_second_phone && (
                <>
                  <br />
                  전화 번호: {data.office_second_phone}
                </>
              )}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
});

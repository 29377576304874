import { formatDateString, getDurationString } from './dateFormat';

export const parseRentDateTime = (pickupTime: string, returnTime: string, format: string) => {
  const parsedPickupTime = formatDateString(pickupTime, format);
  const parsedReturnTime = formatDateString(returnTime, format);
  const timeDiff = getDurationString(pickupTime, returnTime);

  return {
    parsedPickupTime,
    parsedReturnTime,
    timeDiff,
  };
};

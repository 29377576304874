/* eslint-disable operator-linebreak */
import { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { SPACING, COLORS2 } from '^/constants/commonStyles';

import { CarListingSearchBoxViewModel } from '../../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '../../../base/desktop/viewmodel/SearchBoxStepManager';
import { MRTCarListingSearchBoxDesktopContentsArea } from '../contentsArea/MRTCarListingSearchBox.desktop.contentsArea';

import { MRTCitySearchInputButton } from './MRTCitySearchInputButton';
import { MRTScheduleInputButton } from './MRTScheduleInputButton';
import { MRTBirthDayInputMobile } from './MyRealTripBirthDayInput.mobile';

export const MRTPickupReturnDifferentInputsDesktop: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  smaller?: boolean;
}> = observer(function PickupReturnDifferentInputsDesktop({ viewmodel, smaller }) {
  const { stepManager } = viewmodel;

  const pickupCityInputRef = useRef<HTMLInputElement>(null);
  const returnCityInputRef = useRef<HTMLInputElement>(null);
  const hasDifferentPickupReturnLocations = viewmodel.i.hasDifferentPickupReturnLocations.value;

  useEffect(() => {
    if (
      stepManager.currentStep?.type === '1_rental_city' &&
      stepManager.currentStep.autofocus === 'CitySearchInput'
    ) {
      pickupCityInputRef.current?.focus();
    }
    if (stepManager.currentStep?.type === '5_return_city') {
      returnCityInputRef.current?.focus();
    }
  }, [stepManager.currentStep?.type]);

  const stepManagerStepChangeOptions = {
    afterStepChange(newStep: SearchBoxSteps) {
      if (newStep.type === '1_rental_city') {
        viewmodel.i.pickupCitySearch.searchInput.setIsFocused(true);
        pickupCityInputRef.current?.focus();
      }
      if (newStep.type === '5_return_city') {
        viewmodel.i.returnCitySearch.searchInput.setIsFocused(true);
        returnCityInputRef.current?.focus();
      }
    },
  } as const;
  return (
    <form
      className={cx(
        css`
          width: 100%;
          background-color: ${COLORS2.main.MrtWhite};
          border-radius: 10px;
          padding: ${SPACING.spacing24};
          &:focus-visible {
            outline: none;
          }
        `,
      )}
      onSubmit={async (e) => {
        if (viewmodel.submitActionVM.status === 'success') {
          sendTrackingLog({
            action: actions['/'].CLICK_SEARCH_CARS,
          });
          viewmodel.submitActionVM.value.submit();
          if (document.activeElement && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }
        e.preventDefault();
        return false;
      }}
    >
      {/* 다른 장소 반납 체크박스 */}
      {!smaller && (
        <button
          type="button"
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${SPACING.spacing8};

            margin-bottom: 16px;
            width: fit-content;
            color: #434343;

            &:hover {
              cursor: pointer;
            }
          `}
          onClick={() => {
            viewmodel.i.hasDifferentPickupReturnLocations.set(
              !viewmodel.i.hasDifferentPickupReturnLocations.value,
            );
            // FIXME: calendar reset as requested...(?????)
            //          better: do validation...
            viewmodel.i.scheduleTimeViewModel.reset();
            viewmodel.i.calendarStateViewModel.reset();
            viewmodel.i.tempReturnCitySearch.reset();
            viewmodel.i.tempReturnCityCode.set(null);
            viewmodel.i.tempReturnLocationId.set(null);
          }}
        >
          <img
            alt="rental-location-different"
            src={
              viewmodel.i.hasDifferentPickupReturnLocations.value
                ? 'ic_checkbox/checkedbox.svg'
                : 'ic_checkbox/uncheckedbox.svg'
            }
          />
          다른 곳에서 반납하고 싶어요.
        </button>
      )}
      <div
        id="top-line"
        className={cx(
          css`
            width: 100%;
          `,
        )}
      >
        {/* 대여 도시 검색 */}
        <MRTCitySearchInputButton
          stepId="1_rental_city"
          isDifferentReturn
          placeholder="대여 장소"
          inputRef={pickupCityInputRef}
          viewmodel={viewmodel.i.pickupCitySearch}
          title="대여/반납 장소"
          showSuggestions={stepManager.currentStep?.type === '1_rental_city'}
          onTyping={() => {
            if (viewmodel.stepManager.currentStep?.type !== '1_rental_city_search') {
              viewmodel.stepManager.replaceStepWithOptions(
                {
                  type: '1_rental_city_search',
                  autofocus: 'CitySearchInput',
                },
                stepManagerStepChangeOptions,
              );
            }
          }}
          onSelectCity={(cityCode, cityKoName) => {
            if (isOntra(cityCode)) {
              viewmodel.showDifferentLocationAlert.set(true);
              viewmodel.i.tempPickupCitySearch.selectedCityCode.set(null);
              viewmodel.i.tempPickupCitySearch.tempSearchInput.set('');
              viewmodel.i.tempPickupCitySearch.searchInput.set('');
              viewmodel.i.pickupCitySearch.searchInput.set('');
              return;
            }
            viewmodel.i.tempPickupCitySearch.selectedCityCode.set(cityCode);
            viewmodel.i.tempPickupCitySearch.tempSearchInput.set(cityKoName || '');
            viewmodel.i.pickupCitySearch.searchInput.set('');
            viewmodel.i.tempPickupCitySearch.searchInput.set('');
            viewmodel.i.tempPickupLocationId.set(null);
            viewmodel.i.pickupLocationId.set(null);
            stepManager.replaceStepWithOptions(
              {
                type: '2_rental_office',
              },
              stepManagerStepChangeOptions,
            );
          }}
          onClickCitySearchInputBtn={() => {
            pickupCityInputRef.current?.focus();
            if (viewmodel.i.pickupCityCode) {
              viewmodel.i.pickupCitySearch.showAutosuggestList.set(false);
            }
            if (stepManager.currentStep?.type !== '1_rental_city') {
              stepManager.replaceStepWithOptions(
                {
                  type: '1_rental_city',
                  autofocus: 'CitySearchInput',
                },
                stepManagerStepChangeOptions,
              );
            }
            if (viewmodel.pickupLocation?.location_id) {
              stepManager.replaceStepWithOptions(
                {
                  type: '2_rental_office',
                  // autofocus: 'CitySearchInput',
                },
                stepManagerStepChangeOptions,
              );
            }
          }}
          office={viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description}
        />
      </div>

      {/* 반납 도시 검색 */}
      <div key="5_return_city">
        <MRTCitySearchInputButton
          stepId="5_return_city"
          isDifferentReturn
          placeholder="반납 장소"
          inputRef={returnCityInputRef}
          inputElemProps={{
            autoFocus: stepManager.currentStep?.type === '5_return_city',
          }}
          viewmodel={viewmodel.i.returnCitySearch}
          title={null}
          showSuggestions={stepManager.currentStep?.type === '5_return_city'}
          onTyping={() => {
            if (viewmodel.stepManager.currentStep?.type !== '5_return_city_search') {
              viewmodel.stepManager.replaceStepWithOptions(
                {
                  type: '5_return_city_search',
                  // autofocus: 'CitySearchInput',
                },
                stepManagerStepChangeOptions,
              );
            }
          }}
          onSelectCity={(cityCode, cityKoName) => {
            if (isOntra(cityCode)) {
              viewmodel.showDifferentLocationAlert.set(true);
              viewmodel.i.tempReturnCitySearch.selectedCityCode.set(null);
              viewmodel.i.tempReturnCitySearch.tempSearchInput.set('');
              viewmodel.i.tempReturnCitySearch.searchInput.set('');
              viewmodel.i.returnCitySearch.searchInput.set('');
              return;
            }
            viewmodel.i.tempReturnCitySearch.selectedCityCode.set(cityCode);
            viewmodel.i.tempReturnCitySearch.tempSearchInput.set(cityKoName || '');
            viewmodel.i.returnCitySearch.searchInput.set('');
            viewmodel.i.tempReturnCitySearch.searchInput.set('');
            viewmodel.i.tempReturnLocationId.set(null);
            viewmodel.i.returnLocationId.set(null);
            stepManager.replaceStepWithOptions(
              {
                type: '6_return_office',
              },
              stepManagerStepChangeOptions,
            );
          }}
          onClickCitySearchInputBtn={() => {
            returnCityInputRef.current?.focus();
            if (viewmodel.i.returnCityCode) {
              viewmodel.i.returnCitySearch.showAutosuggestList.set(false);
            }

            if (viewmodel.returnLocation?.location_id) {
              stepManager.replaceStepWithOptions(
                {
                  type: '6_return_office',
                  // autofocus: 'CitySearchInput',
                },
                stepManagerStepChangeOptions,
              );
              return;
            }
            stepManager.replaceStepWithOptions(
              {
                type: '5_return_city',
              },
              stepManagerStepChangeOptions,
            );
          }}
          disabled={
            !viewmodel.i.pickupCitySearch.searchInput.value ||
            !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
          }
          office={viewmodel.returnLocation?.locationDescriptions.get('ko')?.description}
        />
      </div>
      <MRTCarListingSearchBoxDesktopContentsArea viewmodel={viewmodel} />
      <div
        id="bottom-line"
        className={cx(
          css`
            display: grid;
            grid-template-columns: minmax(auto, 745px) minmax(auto, 161px) min-content;
            align-items: end;
            gap: 8px;
            width: 100%;
            margin-top: 8px;
          `,
        )}
      >
        {/* 일정 선택 */}
        <MRTScheduleInputButton
          placeholder="언제 떠나시나요?"
          placeholderIcon="calendar.svg"
          viewmodel={viewmodel}
          title="대여/반납 일시"
          onClick={() => {
            stepManager.replaceStepWithOptions(
              {
                type: '3_schedule',
              },
              stepManagerStepChangeOptions,
            );
          }}
          disabled={
            hasDifferentPickupReturnLocations
              ? !viewmodel.i.pickupCitySearch.searchInput.value ||
                !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description ||
                !viewmodel.i.returnCitySearch.searchInput.value ||
                !viewmodel.returnLocation?.locationDescriptions.get('ko')?.description
              : !viewmodel.i.pickupCitySearch.searchInput.value ||
                !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
          }
          isSelected={stepManager.currentStep?.type === '3_schedule'}
          value={viewmodel.i.calendarStateViewModel.selection.start}
          valueEnd={viewmodel.i.calendarStateViewModel.selection.end}
          type="pickupReturn"
        />

        {/* 운전자 생년월일 */}
        <MRTBirthDayInputMobile viewmodel={viewmodel} />

        {/* 검색 버튼 */}
        <button
          type="submit"
          disabled={viewmodel.submitActionVM.status !== 'success'}
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;

            border-radius: 8px;
            background-color: ${COLORS2.main.MrtBlue1} !important;
            color: #ffffff;
            /* margin-bottom: ${SPACING.spacing16}; */
            width: 90px;
            height: ${SPACING.spacing48};
            padding: 0 ${SPACING.spacing16};

            &:disabled {
              background-color: ${COLORS2.main.MrtBlue2} !important;
              cursor: not-allowed;
            }
          `}
        >
          검색
        </button>
      </div>
    </form>
  );
});

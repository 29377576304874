const CancellationAndRefundHertzPPD = () => {
  return (
    <>
      <p className="text-default font-light tablet-max:text-small">
        트리모를 통해 예약한 경우, 트리모 취소 및 환불 규정에 따라 환불이 진행됩니다. 예약 취소
        시점에 따라 다음과 같이 취소 수수료를 제한 후 환불이 진행될 수 있습니다.
      </p>
      <p className="mt-3 text-default font-light tablet-max:text-small">
        <p className="font-bold">예약 변경</p>
        <p className="mt-2">- 예약 변경은 취소 후 재 예약하셔야 합니다.</p>
        <br />
        <p className="font-bold">예약 취소</p>
        <p className="mt-2">
          - 취소는 반드시 트리모를 통해 진행하셔야 합니다. 다른 경로(타 홈페이지 또는 고객센터 등)를
          통해 취소할 경우 불이익이 발생할 수 있습니다.
          <br />- 픽업 시간의 한국 시간 기준, 수수료가 발생할 수 있습니다. <br />
          1) 픽업 72시간 이전 취소 시: 없음
          <br />
          2) 픽업 72시간 이내 취소 시: 결제 금액의 10%에 해당하는 취소 수수료 발생
          <br />
          3) 픽업 시간 이후 취소 시(NO-SHOW 등): 결제 금액의 10%에 해당하는 취소 수수료 발생
          <br />
          *NO-SHOW: 예약취소/변경 없이 차량을 픽업하지 않는 경우를 말합니다.
        </p>
      </p>
    </>
  );
};

export default CancellationAndRefundHertzPPD;

import React, { RefObject } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { SPACING, COLORS2, font, INPUT } from '^/constants/commonStyles';

import { ListingPageOnlineTravelViewModel } from '../viewmodel/ListingPage.OnlineTravel.viewmodel';

export const ListingPageOnlineTravelMobileHeader: React.FC<{
  isFilterButtonVisible: boolean;
  viewmodel: ListingPageOnlineTravelViewModel;
  carClassChipsScrollRef: RefObject<HTMLDivElement>;
}> = observer(function ListingPageOnlineTravelMobileHeader({ viewmodel }) {
  const { startDate, endDate } = viewmodel.calendarStateViewModel;
  const { pickupHour, pickupMinute, returnHour, returnMinute } =
    viewmodel.carListingSearchBox.i.scheduleTimeViewModel;

  const pickupDateStr = startDate
    ? `${startDate?.monthFrom0 + 1}월 ${startDate.day}일 ${pickupHour.value}:${pickupMinute.value}`
    : '';
  const returnDateStr = endDate
    ? `${endDate?.monthFrom0 + 1}월 ${endDate.day}일 ${returnHour.value}:${returnMinute.value}`
    : '';

  const pickupLocationName = viewmodel.routeParamsValues.pLocationName;

  const returnLocationName = viewmodel.routeParamsValues.rLocationName;

  const pickupReturnDiff = viewmodel.routeParamsValues.pIsDiff;

  const pickupReturnLocationName = pickupReturnDiff
    ? `${pickupLocationName} - ${returnLocationName}`
    : pickupLocationName;

  return (
    <div
      className={cx(
        css`
          position: sticky;
          top: 0px;
          height: 141px;
          background-color: ${COLORS2.main.MrtWhite};
          padding: ${SPACING.spacing10} ${SPACING.spacing24};
          padding-top: ${SPACING.spacing16};
          box-shadow: 0 12px 5px -5px rgba(221, 221, 221, 0.43);
        `,
      )}
    >
      <section
        id="search-filter-box-container"
        onClick={() =>
          viewmodel.carListingSearchBox.stepManager.pushStep({
            type: '0_none_selected',
          })
        }
        className={css`
          height: 60px;
          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 0 ${SPACING.spacing16};
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
        tabIndex={-1}
        role="button"
      >
        <img
          src="./ic_search.svg"
          alt="search"
          className={css`
            width: 20.3px;
            height: 20.3px;
          `}
        />
        <div
          id="rentalCity"
          className={cx(
            css`
              flex-grow: 1;
              padding-left: 16px;
              display: flex;
              flex-direction: column;
            `,
            INPUT.overflowEllipsis,
          )}
        >
          <span className={cx(font.Caption1_Bold, INPUT.overflowEllipsis)}>
            {pickupReturnLocationName}
          </span>
          <span className={cx(font.Caption2_Light, INPUT.overflowEllipsis)}>
            {pickupDateStr}~{returnDateStr}
          </span>
        </div>
      </section>
    </div>
  );
});

/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';

import { css } from '@emotion/css';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import { DialogWithCloseButton } from '^/commonComponents/ui/DialogWithCloseButton';
import { borders, font2 } from '^/constants/commonStyles';
import { wonCurrency } from '^/pages/Booking/components/Bill/constants';
import { PPDOnsitePaymentHoverMessage } from '^/pages/ReservationDetail/onsiteInfoMessages';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { parseFloatNumberOrNumberString } from '^/util/zod/numberFromString';

import { AdditionFees } from './AdditionFees';
import * as styles from './ReservationDetailsMobile.bill.styles';
import { formatDateString } from '^/util/dateFormat';

function isPPDReservation(data: ReservationDetailed) {
  if (data.pay_type !== 'PPD') {
    throw new Error('DummyErrorForTypeFiltering');
  }
  return data;
}

export const ReservationDetailsMobileBillPPDArea: React.FC<{
  data: ReturnType<typeof isPPDReservation>;
}> = ({ data }) => {
  const { t } = useTranslation();
  const [isOnSiteInfoDialogOpen, setIsOnSiteInfoDialogOpen] = useState(false);

  const currency = data.paymentDetails.onSiteCurrency;
  const paymentTime = formatDateString(data.date_request, 'YYYY.MM.DD HH:mm (z)', 'Asia/Seoul');

  return (
    <>
      <DialogWithCloseButton
        isOpen={isOnSiteInfoDialogOpen}
        setIsOpen={setIsOnSiteInfoDialogOpen}
        contentWrapperClassName={styles.OnsiteHoverMessageStyles.contentWrapperClassName}
        content={
          <pre className={styles.OnsiteHoverMessageStyles.contentClassName}>
            {PPDOnsitePaymentHoverMessage}
          </pre>
        }
      />
      <div
        className={[
          'mt-4 flex flex-row justify-between',
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <p className="text-big tablet:text-semilarge">{t('fee')}</p>
        <p className="text-semilarge font-bold">{formatKRWWonAmount(data.rate_amount)} 원</p>
      </div>
      {data.extra_charge && (data.extra_charge.additional_amount || 0) > 0 && (
        <div
          className={css`
            margin-top: 16px;
          `}
        >
          <div
            className={[
              'flex flex-col gap-1.5',
              // borders.DEBUG_blueBorderDashed,
            ].join(' ')}
          >
            <AdditionFees
              extra_charge={data.extra_charge}
              onSiteCurrency={data.extra_equipments_json.currency}
            />
          </div>
        </div>
      )}

      <Horizon marginTop="16px" marginBottom="16px" />

      <div className="mt-4 flex flex-row justify-between tablet:mt-0">
        <p className={font2.Body1_Regular}>결제하신 금액</p>
        <p className="text-semihuge font-bold">{formatKRWWonAmount(data.rate_amount)} 원</p>
      </div>
      {(data.extra_charge?.additional_amount || null) && (
        <div
          className={[
            'mt-4 flex flex-row justify-between',
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <div
            className={[
              css`
                display: flex;
                flex-direction: row;
                gap: 6px;
                align-items: center;
                height: fit-content;
              `,
              // borders.DEBUG_greenBorderDashed,
            ].join(' ')}
          >
            <p className="text-big tablet:text-semilarge">현장 결제 예상금액</p>
            <InfoIcon width={20} height={20} onClick={() => setIsOnSiteInfoDialogOpen(true)} />
          </div>
          <div>
            <div className="flex flex-col items-end gap-1.5">
              <p
                className="
                  text-semihuge
                  font-bold
                  tablet:text-semilarge

                "
              >
                {formatKRWWonAmount(data.extra_charge?.additional_amount, 0.00001)}
                &nbsp;
                {currency === wonCurrency ? t('won') : currency}
              </p>
              <p className="text-small font-light text-trimo-gray">
                약 &nbsp;
                {formatKRWWonAmount(data.extra_charge?.additional_rate_amount)}
                &nbsp;
                {t('won')}
              </p>
            </div>
          </div>
        </div>
      )}

      <Horizon marginTop="16px" marginBottom="16px" />
      <div className="mt-4 flex flex-row justify-between tablet:mt-0">
        <p className="text-big tablet:text-semilarge">결제 수단</p>
        <p className="text-semilarge font-bold">신용카드</p>
      </div>
      <div className="mt-4 flex flex-row  justify-between">
        <p className="text-semilarge mobile:text-big">결제 일시</p>
        <p className="text-semilarge font-bold">{paymentTime}</p>
      </div>
    </>
  );
};

import { z } from 'zod';

import { defineResponseWithData } from '../DefineResponse';

export const otClientAPIResponseItem = z.object({
  plan_info: z.object({
    code: z.string(),
    name: z.string().or(z.null()),
    brand_code: z.string().or(z.null()),
    shop_name: z.string().or(z.null()),
    shop_code: z.string().or(z.null()),
    shop_address: z.string().or(z.null()),
    shuttle_flag: z.number(),
  }),
  car_info: z.object({
    model_name: z.string().or(z.null()),
    model_code: z.string().or(z.null()),
    class_name: z.string().or(z.null()),
    class_code: z.string().or(z.null()),
    image: z.string().or(z.null()),
  }),
  payment_info: z.object({
    domestic_price: z.number(),
    foreign_price: z.number(),
    foreign_currency_code: z.string().or(z.null()),
  }),
  company_info: z.object({
    id: z.string().or(z.null()),
    name: z.string().or(z.null()),
  }),
  equipment_info_list: z.array(
    z.union([
      z.object({ name: z.string().or(z.null()), info: z.string().or(z.null()) }),
      z.object({ name: z.string().or(z.null()), info: z.number() }),
    ]),
  ),
});

export const otClientAPIResponseType = defineResponseWithData(otClientAPIResponseItem.array(), 201);

export interface OTSearchDefinedCarRSType {
  schedule_info: {
    pickup: {
      shop_code: string;
      time: string;
    };
    return: {
      shop_code: string;
      time: string;
    };
  };

  plan_info: {
    code: string;
    name: string;
    brand_code: string;
    shop_name: string;
    shop_tel: string;
    shop_address: string;
    shop_notice: string;
    shop_access: string;
    comment: string;
    shuttle_flag: number;
    time_price: object;
    insurance_flag: number;
    cancel_policy: CancelInfo[];
    is_pickup_soon: boolean;
  };

  car_info: {
    model_name: string;
    model_code: string;
    class_name: string;
    vehicle_code: string;
    image: string;
  };

  payment_info: {
    foreign_currency: string;
    basic_price: number;
    drop_off_fee: number;
    discount_fee: number;
    insurance_price: number;
    exchange_rate: number;
    card_flag: number;
  };

  company_info: CompanyInfo;
  equipment_info_list: EquipmentInfo[];
  option_info_list: OptionInfo[];
}

export interface CompanyInfo {
  id: string;
  name: string;
}

export interface EquipmentInfo {
  name: string;
  info: any;
}

interface OptionInfo {
  code: string;
  name: string;
  foreign_price: number;
  foreign_currency_code: string;
}

export interface CancelInfo {
  start: number;
  stop: number;
  value: string;
  cmt: string;
}

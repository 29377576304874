/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { css } from '@emotion/css';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  borders,
  COLORS,
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2_AS_FOREGROUND,
  COLORS2_AS_BACKGROUND,
  COLORS2,
  font2,
} from '^/constants/commonStyles';
import { isReservationCancelDisabled } from '^/pages/ReservationList/isReservationDisabled';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';

import { HoverToReveal } from '../../../../commonComponents/ui/HoverToReveal';
import { AdditionalFees2 } from '../../AdditionalFees2';
import { PPDOnsitePaymentHoverMessage } from '../../onsiteInfoMessages';

import {
  CancelButtonStyle,
  flexRightmostItemRow,
  flexSpacedRowWithVerticalCenter,
} from './ReservationDetailDesktop.commonStuff';
import { CancellationWarningBeforePickup72Hours } from '../../CancellationWarningBeforePickup72hours';
import { formatDateString } from '^/util/dateFormat';
// import { actions, sendTrackingLog } from '^/api/trackingLog';

function isBookedAndPPDReservation(data: ReservationDetailed) {
  if (data.res_status !== 'booked' || data.pay_type !== 'PPD') {
    throw new Error('DummyErrorForTypeFiltering');
  }
  return data;
}

export const ReservationDetailsBillPPDBooked: React.FC<{
  data: ReturnType<typeof isBookedAndPPDReservation>;
  onClickCancelReservation(reservationToCancel: ReservationDetailed): void;
}> = observer(function ReservationDetailsBillPPDBooked({ data, onClickCancelReservation }) {
  const { t } = useTranslation();
  const pickupDate = new Date(data.pickup_datetime);
  // const tax = data.tax && JSON.parse(data.tax);
  const isCancelDisabled = isReservationCancelDisabled({
    pickupDate,
    cancelEnabled: data.cancelEnabled,
    payType: data.pay_type,
  });

  const paymentTime = formatDateString(data.date_request, 'YYYY.MM.DD HH:mm (z)', 'Asia/Seoul');

  return (
    <div className={[css``, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
      <h3 className={[font2.Title3].join(' ')}>{t('total')}</h3>

      <div
        id="separator1"
        className={[
          MARGINS.TOP_AND_BOTTOM.spacing16,
          css`
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />

      <div className={['flex flex-col'].join(' ')}>
        <div
          className={[COLORS2_AS_FOREGROUND.main.MrtBlack, flexSpacedRowWithVerticalCenter].join(
            ' ',
          )}
        >
          <p className={font2.Headline1_Regular}>{t('fee')}</p>
          <p className={font2.Headline1_Bold}>{formatKRWWonAmount(data.rate_amount)} 원</p>
        </div>
        {data.extra_charge && (data.extra_charge.additional_amount || 0) > 0 && (
          <div
            className={[
              // 'flex flex-col gap-1.5',
              // borders.DEBUG_greenBorderDashed,
              MARGINS.TOP.spacing12,
            ].join(' ')}
          >
            <AdditionalFees2
              extra_charge={data.extra_charge}
              onSiteCurrency={data.extra_equipments_json.currency}
            />
          </div>
        )}

        <div
          id="separator"
          className={[
            css`
              margin-top: ${SPACING.spacing16};
              margin-bottom: ${SPACING.spacing16};
              background-color: ${COLORS.LIGHT_GREY};
              height: 2px;
            `,
          ].join(' ')}
        />
        <div
          className={[
            flexSpacedRowWithVerticalCenter,
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <span className={[font2.Headline1_Bold].join(' ')}>결제하신 금액</span>

          <span className={[font2.Title2].join(' ')}>
            {formatKRWWonAmount(data.rate_amount)} 원
          </span>
        </div>
        {(data.extra_charge?.additional_amount && (
          <>
            <div
              className={[
                flexSpacedRowWithVerticalCenter,
                css`
                  position: relative;
                `,
                // borders.DEBUG_goldDashed,
              ].join(' ')}
            >
              <div
                className={[
                  font2.Headline1_Bold,
                  // borders.DEBUG_goldDashed,
                  css`
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    height: fit-content;
                    gap: 6px;
                  `,
                ].join(' ')}
              >
                <span>현장 결제 예상금액</span>
                <HoverToReveal
                  toReveal={
                    <div
                      className={[
                        font2.Caption1_Light,
                        PADDINGS.TOP_AND_BOTTOM.spacing16,
                        COLORS2_AS_BACKGROUND.sub.MrtGray3,
                        css`
                          position: absolute;
                          left: 0;
                          right: 0;
                          top: 100%;
                          padding-left: 10px;
                          padding-right: 10px;
                          border-radius: 10px;
                        `,
                      ].join(' ')}
                    >
                      {PPDOnsitePaymentHoverMessage}
                    </div>
                  }
                />
              </div>

              <p className={[font2.Title2].join(' ')}>
                {formatKRWWonAmount(Number(data.extra_charge.additional_amount), 0.01)}
                &nbsp;
                {data.paymentDetails.onSiteCurrency}
              </p>
            </div>
            <p
              className={[
                font2.Caption1_Light,
                flexRightmostItemRow,
                COLORS2_AS_FOREGROUND.sub.MrtGray1,
              ].join(' ')}
            >
              약 &nbsp;
              {formatKRWWonAmount(Number(data.extra_charge.additional_rate_amount), 0.01)}
              &nbsp;
              {t('won')}
            </p>
          </>
        )) ||
          null}
      </div>

      <div
        id="separator2"
        className={[
          css`
            margin-top: ${SPACING.spacing16};
            margin-bottom: ${SPACING.spacing16};
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />
      <div className={[flexSpacedRowWithVerticalCenter].join(' ')}>
        <p className={font2.Headline1_Regular}>결제 수단</p>
        <p className={font2.Headline1_Bold}>신용카드</p>
      </div>
      <div className={[flexSpacedRowWithVerticalCenter, MARGINS.TOP.spacing12].join(' ')}>
        <p className={font2.Headline1_Regular}>결제 일시</p>
        <p className={font2.Headline1_Bold}>{paymentTime}</p>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            onClickCancelReservation(data);
            // sendTrackingLog({
            //   action: actions.RESERVATION_DETAIL.CLICK_RESERVATION_CANCEL,
            // });
          }}
          disabled={isCancelDisabled}
          className={CancelButtonStyle}
        >
          {t('cancelBooking')}
        </button>
        {isCancelDisabled && (
          <div
            className={[
              font2.Caption1_Light,
              css`
                display: flex;
                flex-direction: column;
                align-items: center;
                color: ${COLORS2.main.MrtBlack};
                margin-top: ${SPACING.spacing16};
              `,
            ].join(' ')}
          >
            <CancellationWarningBeforePickup72Hours />
          </div>
        )}
      </div>
    </div>
  );
});
